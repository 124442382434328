var isCartItemsLoad = false
var isWidgetLoad = false

const selectors = {
  loading: 'is-loading',
  imageUpdate: 'is-image-update',
  btnAddCart: '[data-add-to-cart]',
}

const animateCartItem = (status) => {
  const cartItemsEl = document.querySelector('.js-cart-items');
  if (status && !cartItemsEl?.classList.contains(selectors.loading)) {
    cartItemsEl?.classList.add(selectors.loading);
  } else if (!status && cartItemsEl?.classList.contains(selectors.loading)) {
    cartItemsEl?.classList.remove(selectors.loading);
  }
}

function updateImage(products, cart) {
  products.forEach(product => {
    let imageUrl = ''
    const url = product.link + '?view=thumbnails-metafield'

    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.text()
      })
      .then(html => {
        const parser = new DOMParser()
        const doc = parser.parseFromString(html, 'text/html')
        const bodyText = doc.body.textContent
        const bodyJson = JSON.parse(decodeURIComponent(bodyText).replace(/\+/g, ' '));

        if (bodyJson?.thumbnail_image !== '') {
          imageUrl = bodyJson?.thumbnail_image
        }

        const queryString = `.product-id-${product.id}`
        const productEl = cart.querySelector(queryString)
        if (imageUrl !== '' && productEl) {
          const dataThumbLoaded = productEl.getAttribute('data-thumb-loaded') || false
          if (!dataThumbLoaded) {
            const imageEl = productEl.querySelector('img')

            if (bodyJson?.variant_images) {
              const variantImageUrl = bodyJson?.variant_images.filter(variant => variant.id === product?.id && variant?.variant_image !== '')

              imageUrl = variantImageUrl.length ? variantImageUrl[0]?.variant_image : imageUrl
            }

            if (imageEl && imageUrl !== '' && imageUrl !== undefined) {
              imageEl.src = imageUrl
              imageUrl = ''
              productEl.classList.add(selectors.imageUpdate)
              productEl.setAttribute('data-thumb-loaded', 'true')
            }
          }
        } else if (productEl) {
          if (!productEl.classList.contains(selectors.imageUpdate)) {
            productEl.classList.add(selectors.imageUpdate)
          }
          productEl.setAttribute('data-thumb-loaded', 'true')
        }
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error)
        animateCartItem(false)
      })
  })
}

function updateImageLineItem(products, cart) {
  products.forEach(product => {
    let imageUrl = ''
    const url = product.url + '&view=thumbnails-metafield'

    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.text()
      })
      .then(html => {
        const parser = new DOMParser()
        const doc = parser.parseFromString(html, 'text/html')
        const bodyText = doc.body.textContent
        const bodyJson = JSON.parse(decodeURIComponent(bodyText).replace(/\+/g, ' '));

        if (bodyJson?.thumbnail_image !== '') {
          imageUrl = bodyJson?.thumbnail_image
        }

        const queryString = `[data-variant-id="${product.id}"]`
        const productEl = cart.querySelector(queryString)
        if (imageUrl !== '' && productEl) {
          const dataThumbLoaded = productEl.getAttribute('data-thumb-loaded') || false
          if (!dataThumbLoaded) {
            const imageEl = productEl.querySelector('img')

            if (bodyJson?.variant_images) {
              const variantImageUrl = bodyJson?.variant_images.filter(variant => variant.id === product?.id && variant?.variant_image !== '')

              imageUrl = variantImageUrl.length ? variantImageUrl[0]?.variant_image : imageUrl
            }

            if (imageEl && imageUrl !== '' && imageUrl !== undefined) {
              imageEl.src = imageUrl
              imageUrl = ''
              productEl.classList.add(selectors.imageUpdate)
              productEl.setAttribute('data-thumb-loaded', 'true')
            }
          }
        } else if (productEl) {
          if (!productEl.classList.contains(selectors.imageUpdate)) {
            productEl.classList.add(selectors.imageUpdate)
          }
          productEl.setAttribute('data-thumb-loaded', 'true')
        }
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error)
        animateCartItem(false)
      })
  })
}

function updateWidget(cart) {
  const listWidget = cart?.querySelectorAll('.rebuy-widget') || false
  const rebuyData = window.Rebuy
  const widgets = rebuyData.widgets

  if (listWidget && listWidget.length) {
    listWidget.forEach(el => {
      const listProductEls = el?.querySelectorAll('.rebuy-product-block') || false

      listProductEls && widgets.length && widgets?.forEach(widget => {
        const products = widget.data.products
        if (products && products.length) {
          updateImage(products, cart)
          isWidgetLoad = true
        }
      })
    })
  }
}

function updateCartItems(cart) {
  const widgets = cart.querySelectorAll('[data-smartcart-items]') || false
  const rebuyData = window.Rebuy

  if (widgets && widgets.length) {
    widgets.forEach(el => {
      const listProductEls = el?.querySelectorAll('.rebuy-cart__flyout-item') || false

      const products = rebuyData?.Cart?.cart?.items
      if (products && products.length && listProductEls) {
        updateImageLineItem(products, cart)
        isCartItemsLoad = true
      }
    })
  }

}

async function updateThumbnail(cart) {
  if (cart) {
    !isCartItemsLoad && updateCartItems(cart)
    !isWidgetLoad && updateWidget(cart)
  }
}

const smartCartReadyListeners = ['rebuy:smartcart.ready', 'DOMContentLoaded']

smartCartReadyListeners.forEach(event => {
  document.addEventListener(event, () => {
    const buttonAddCart = document.querySelectorAll(selectors.btnAddCart)

    document.body.classList.add('smart-cart--ready')
    if (buttonAddCart.length) {
      buttonAddCart.forEach(button => {
        if (button.dataset.available === 'true') {
          button.setAttribute('aria-disabled', 'false')
          button.removeAttribute('disabled')
        }
      })
    }
  })
});

document.addEventListener('rebuy:smartcart.show', (event) => {
  const cartEl = event?.detail?.smartcart?.cartElement
  
  if (cartEl) {
    isCartItemsLoad = false
    isWidgetLoad = false
    setTimeout(() => {
      updateThumbnail(cartEl)
    }, 900);
  }
})

document.addEventListener('rebuy:cart.change', (event) => {
  const cartEl = event?.detail?.smartcart?.cartElement
  if (cartEl) {
    animateCartItem(true);
  }
});

document.addEventListener("rebuy:smartcart.line-item-increase", () => {
  animateCartItem(true);
});

document.addEventListener("rebuy:smartcart.line-item-removed", () => {
  animateCartItem(true);
});

document.addEventListener("rebuy:smartcart.line-item-removed", () => {
  animateCartItem(true);
});

document.addEventListener('rebuy:cart.enriched', () => {
  setTimeout(() => {
    animateCartItem(false);
  }, 500)
});